import React, { useEffect, useState } from "react";
import './zone_de_livraison.css';
import star from '../../images/crocsdelanight/crocs-2.png';
import { Typography } from '@material-ui/core';

import {URL, } from '../middlewares/request';
import axios from "axios";

const ZoneLivraison = () => {
	const [list, setList] = useState([]);
	const [load, setLoad] = useState(false);

	const fetchData = async () => {
        console.log("je lance")
        const res = await axios.get(URL + "zone-livraison/");
        setList(res.data)
        setLoad(true)
    };

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div id="livraison" className="livraison">
			<div className="livraisonTitleContainer">
				<Typography gutterBottom variant="h5" component="h1" className="livraisonTitle">
					<img className="star" src={star} />
					<span> ZONE DE LIVRAISON</span>
					<img className="star" src={star} />
				</Typography>
			</div>
			<div className="livraison_container" />
			<br />
			<h3>
				Nos livraisons se font dans le respect dans l’environnement avec un véhicule hybride et tous nos Crocs
				Burgers arrivent toujours chauds prêts à être dévorer !
			</h3>
			<br />
			<h4>Prévoir 45 minutes d’attente le temps qu’on fasse ça bien {';)'}</h4>
			<br />
			<ul>
				{load ? 
					list.map((i)=>{
						return(<li> {i.nom + " (" + i.montant + "€): " + i.description}  </li>)	
					})
				: 
				null}
			</ul>
		</div>
	);
};

export default ZoneLivraison;
