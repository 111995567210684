import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// *  Writing the Slices

//+ createSlice returns a "slice" object that contains the generated reducer function as a field named reducer,
//+ and the generated action creators inside an object called actions.
export const printerSlice = createSlice({
  name: "printer",
  initialState: [{printer: null}],
  reducers: {
    setPrinter: (state, action) => {
        console.log("INSERT PRINTER")
        state[0].printer = action.payload
    },
    deletePrinter: (state) => {
        state[0].printer = null
    },
  },
});

//+ generated action creator functions :return an object with payload and type
export const {
  setPrinter,
  deletePrinter
} = printerSlice.actions;

// useSelector(state => state.baskets) :returns the state for baskets
export const selectPrinter = (state) => state;

// + the generated reducer function
export default printerSlice.reducer;