import React, { Component } from 'react';
import Header from './header/Header';
import Plats from './plats/Plats';
import Galerie from './galerie/Galerie';
import ZoneLivraison from '../Zone_de_livraison/Zone_de_livraison';
import Contact from '../contact/Contact'


class Home extends Component {
    render() {
        return (
            <div>
                <Header />
                <Plats />
                <ZoneLivraison />
                <Contact />
                <Galerie />
            </div>
        );
    }
}

export default Home;