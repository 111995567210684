import React from 'react';
import star from '../../images/crocsdelanight/crocs-2.png';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './Contact.css';

const Contact = () => {
	return (
		<div className="contact">
			<div className="contactTitleContainer">
				<Typography gutterBottom variant="h5" component="h1" className="contactTitle">
					<img className="star" src={star} />
					<span> CONTACT </span>
					<img className="star" src={star} />
				</Typography>
			</div>
			<div className="contact_container">
				<ul className="contact_elements">
					<li>
						Adresse
						<p>Rue Jean Jaurès, 78190 Trappes</p>
					</li>
					<li>
						Horaires
						<p>Vendredi au Dimanche de 22h00 à 3h00</p>
					</li>
					<li>Numero</li>
				</ul>
				<Button id="Btn_number">
					<strong>0615244088</strong>
				</Button>
			</div>
		</div>
	);
};

export default Contact;
