import React, { Component } from 'react';
import './Header.css';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


import {Grid } from '@material-ui/core';

class Header extends Component {
    render() {
        return (
            <div id="header">
                <Grid container alignItems="center" justifyContent="center" className="Grid_Container">
                    
                        <div className="Div_Text_Header">
                            <h1>T 'as les crocs cette nuit ?</h1>
                            <h2>Dévore nos recettes de Crocs Burgers! </h2>
                        
                        <Button id="Btn_Commander_Accueil">
                            <Link to="/carte" className="commanderHeader">
                                <strong>J'ai les crocs!</strong>
                            </Link>
                        </Button>
                        
                        <Button id="Btn_Commander_Accueil">
                        <HashLink to={"/#livraison"} style={{textDecoration:'none'}}>
                    
                           
                                <strong>Livraison</strong>
                             </HashLink>

                        </Button>
                        </div>
                        
                       
                        
                </Grid>           
            </div>
        );
    }
}

export default Header;