import React, { Component } from 'react';
import "./Cards.css";
import CardItem from './CardItem';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import star from "../../../images/crocsdelanight/crocs-2.png";

import image1 from "../../../images/crocsdelanight/burger_magic.jpg";
import image2 from "../../../images/crocsdelanight/neon_burger.jpg";
import image3 from "../../../images/crocsdelanight/burger_hands.jpg";
import image4 from "../../../images/crocsdelanight/plaine-lune.jpg";
import image5 from "../../../images/crocsdelanight/burger_grill.jpg";
import image6 from "../../../images/crocsdelanight/burger-f.jpg";
// import image5 from "../../../images/Galerie/galerie5.jpg";
// import image6 from "../../../images/Galerie/galerie6.png";
// import image7 from "../../../images/Galerie/galerie7.jpg";
// import image8 from "../../../images/Galerie/galerie8.jpg";
// import image9 from "../../../images/Galerie/galerie9.jpg";



function Galerie() {
    return (
        <>

      <div className="cards">
        {/* <div className="cards__container"></div> */}
        <div className="galeriePage" id="galerie">
            <div className="galerieTitleContainer">
                <Typography gutterBottom variant="h5" component="h1" className="galerieTitle">
                    <img className="star" src={star} /> GALERIE <img className="star" src={star} />
                </Typography>
            </div>
            </div>
        {/* <div className="cards_wrapper"></div> */}
        <ul className="cards__items">
          <CardItem
            src={image1}
            path='/'
            />
          <CardItem
            src={image2}
            path='/'
            />
          <CardItem
            src={image3}
            path='/'
            />
        </ul>
        <ul className="cards__items">
          <CardItem
          src={image4}
          path='/'
          />
          <CardItem
          src={image5}
          path='/'
          />
          <CardItem
            src={image6}
            path='/'
          />
        </ul>
      </div>
      </>
    );
}

export default Galerie;