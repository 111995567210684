import axios from "axios";
import { useEffect, useState /*, useRef*/ } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrinter, selectPrinter } from "../../../app/Redux-slices/printerSlice";

import {
  changenouvelleCommandeLength,
  changeCommandeCoursLength,
} from "../../../app/Redux-slices/adminSlice";
import ProductList from "../product/ProductList";
import { URL } from "../../../middlewares/request";
import ThermalPrinter, {printf} from "../printer/ThermalPrinter"
//import { connect } from "../printer/EpsonPrinter";
//import ReactStopwatch from 'react-stopwatch';
import MarkusLogo from "../../../images/markus_bleu.png"

const NouvelleCommande = () => {
  const dispatch = useDispatch();
  const [commandes, setCommandes] = useState([]);
  const [commandesEnCours, setCommandesEnCours] = useState([]);

  const printer = useSelector(selectPrinter).printer[0].printer
  // const [running, setRunning] = useState(false);
  // const [currentTimeMin, setCurrentTimeMin] = useState(0);

  const fetchCommandes = async () => {
    const { data } = await axios.get(URL + "paiement/nouvelle-commande");
    setCommandes(data);
  };

  const fetchCommandesEnCours = async () => {
    const { data } = await axios.get(URL + "paiement/commande-encours");
    setCommandesEnCours(data);
  };

  useEffect(() => {
    // console.log("Commande: ",commandes)
    // a chaque fois que commande change, on met a jour la longueur de nouvelle commande
    dispatch(changenouvelleCommandeLength(commandes.length));
  }, [commandes, dispatch]);

  useEffect(() => {
    // a chaque fois que commande change, on met a jour la longueur de nouvelle commande
    dispatch(changeCommandeCoursLength(commandesEnCours.length));
  }, [commandesEnCours, dispatch]);

  useEffect(() => {
    fetchCommandesEnCours();
    let timeoutId;
    function getLatestCommandes() {
      fetchCommandes();
      // wait for the response from fetchCommandes , before we recall it (delay of 1minute)
      timeoutId = setTimeout(getLatestCommandes, 1000 * 60);
    }
    getLatestCommandes();

    return () => {
      clearTimeout(timeoutId);
      setCommandes([]);
    };
  }, []);

  const commande_est_vue = async (id) => {
    await axios.put(URL + "paiement/update-commande", {
      id,
      est_vue: true,
    });

    fetchCommandes();
    fetchCommandesEnCours();
  };

  /**
   * Print something or alert if there is no printer, then modifiy value est_vue to "true"
   * @param {*} text 
   * @returns 
   */
  const print = (text) => {
    printf(text, printer)
    commande_est_vue(text.id)
  };

  return (
    <div className="nouvelleCommande admin__container">
      <h1
        style={{
          textAlign: "center",
          marginBottom: "2rem",
          color: "#04295d",
        }}
      >
        {commandes.length
          ? "Vos nouvelles Commandes"
          : "Pas de nouvelles commandes"}
      </h1>

      <ProductList
        nouvelleCommande
        commandes={commandes}
        //action={commande_est_vue}
        action={print}
        btn="confirmer"
      />
    </div>
  );
};

export default NouvelleCommande;
